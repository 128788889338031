<template>
  <div id="APR">
    <!-- HERO
    ================================================== -->
    <section class="section section-top">
      <!-- Cover -->
      <div class="bg-cover" style="background-color: #D75757;"></div>

      <!-- Overlay -->
      <!-- <div class="bg-overlay"></div> -->

      <!-- Triangles -->
      <!-- <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-left"></div>
    <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-right"></div> -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-8 col-lg-7">
            <!-- Preheading -->
            <p
              class="font-weight-medium text-center text-xl text-uppercase text-black"
            >
              <img src="assets/img/logo_bw_apr.svg" />
            </p>

            <!-- Heading -->
            <h1 class="text-black text-center mb-4">
              app reviews & sentiment analysis
            </h1>

            <!-- Subheading -->
            <p class="lead text-black text-center mb-5">
              Keep track of your applications. See how your competition compares
              to you.
            </p>

            <!-- Button -->
            <p class="text-center mb-0">
              <router-link
                :to="`/contact`"
                class="btn btn-outline-dark text-black"
              >
                contact us
              </router-link>
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- SECTIONS
    ================================================== -->
    <section class="section bg-light">
      <!-- Triangle -->
      <div
        class="bg-triangle bg-triangle-dark bg-triangle-top bg-triangle-left"
      ></div>
      <div
        class="bg-triangle bg-triangle-dark bg-triangle-bottom bg-triangle-right"
      ></div>

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <!-- Heading -->
            <h2 class="text-center mb-4">
              Reviews Listed and Summarized
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5">
              Reviews of apps and services managed in a single space.
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row align-items-center">
          <div class="col-md-3 col-lg-3">
            <!-- Item -->
            <div class="mb-5 text-md-left">
              <!-- Icon -->
              <div class="text-primary mb-4 ">
                <img
                  class="img-small"
                  src="assets/img/np_competition_2103940_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                Track your competition
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                Reviews of your competitors at a glance.
              </p>
            </div>

            <!-- Item -->
            <div class="mb-5 text-md-left">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img
                  class="img-small"
                  src="assets/img/np_trophy_801132_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                Monitor your progress
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                See how updates affect perception of your products.
              </p>
            </div>

            <!-- Item -->
            <div class="mb-5 mb-md-0 text-md-left">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img
                  class="img-small"
                  src="assets/img/np_data_2500405_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                Data at a glance
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                Single place for all of your reviews
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 offset-lg-1">
            <!-- Image -->
            <div class="text-center mb-5 mb-md-0">
              <img
                src="assets/img/app_reviews.png"
                alt="..."
                class="img-fluid mx-auto"
              />
            </div>
          </div>
          <div class="col-md-3 col-lg-3 offset-lg-1">
            <!-- Item -->
            <div class="text-left text-md-right mb-5">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img
                  class="img-small"
                  src="assets/img/np_open-ai_2486994_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                Machine Learning
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                Focus only on the relevant information with machine learning
                models that generate inteligent summaries
              </p>
            </div>

            <!-- Item -->
            <div class="text-left text-md-right mb-5">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img
                  class="img-small"
                  src="assets/img/np_sync_2927852_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                Always up-2-date
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                Data is constantly updated, so you that you can focus on what's
                happening right now.
              </p>
            </div>

            <!-- Item -->
            <div class="text-left text-md-right">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img
                  class="img-small"
                  src="assets/img/np_alert_2391128_D75757.svg"
                />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                APP alerts
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                Ever wondered if your compentition is releasing something new?
              </p>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- DATA
  ================================================== -->
    <section class="section bg-light">
      <!-- Line -->
      <div class="line line-top"></div>

      <!-- Triangles -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h2 class="text-center mb-4">
              Data
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5">
              We connect with the data coming from the most popular app stores.
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-md-3 offset-lg-3">
            <!-- Item -->
            <a class="card border-0 mb-3 mb-md-0">
              <!-- Image -->
              <div class="card-img-small">
                <img
                  src="assets/img/app_store.png"
                  alt="App landing"
                  class="img-fluid"
                />
              </div>

              <!-- Body -->
              <div class="card-body">
                <!-- Title -->
                <h4 class="card-title text-center">
                  Apple App Store
                </h4>

                <!-- Body -->
                <p class="card-text text-muted"></p>
              </div>
            </a>
            <!-- / .card -->
          </div>
          <div class="col-md-3">
            <!-- Item -->
            <a class="card border-0 mb-3 mb-md-0">
              <!-- Image -->
              <div class="card-img-small">
                <img
                  src="assets/img/play_store.png"
                  alt="Fullpage scrolling"
                  class="img-fluid"
                />
              </div>

              <!-- Body -->
              <div class="card-body">
                <!-- Title -->
                <h4 class="card-title text-center">
                  Google Play Store
                </h4>

                <!-- Text -->
                <p class="card-text text-muted"></p>
              </div>
            </a>
            <!-- / .card -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- FEATURES
  ================================================== -->
    <section class="section bg-dark">
      <!-- Triangles -->
      <!-- <div class="bg-triangle bg-triangle-light bg-triangle-top bg-triangle-left"></div>
    <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-right"></div> -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <!-- Heading -->
            <h2 class="text-white text-center mb-4">
              Additional Features
            </h2>

            <!-- Subheading -->
            <p class="text-white text-muted text-center mb-5"></p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-md-4">
            <!-- Item -->
            <div class="text-center">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <span class="icon icon-layers icon-2x"></span>
              </div>

              <!-- Heading -->
              <h4 class="text-white mb-3">
                Data Sources
              </h4>

              <!-- Content -->
              <p class="text-white text-muted mb-0">
                We build close relations with business to get and to process
                data from any source.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <!-- Item -->
            <div class="text-center mb-5 mb-md-0">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <span class="icon-comment icon-2x"></span>
              </div>

              <!-- Heading -->
              <h4 class="text-white mb-3">
                Sentiment Analysis
              </h4>

              <!-- Content -->
              <p class="text-white text-muted mb-0">
                Machine learning models tuned to fulfill specific needs of a
                business. Automatic text processing, sentiment analysis and
                summary generation.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <!-- Item -->
            <div class="text-center mb-5 mb-md-0">
              <!-- Icon -->
              <div class="text-primary mb-4">
                <span class="icon-code icon-2x"></span>
              </div>

              <!-- Heading -->
              <h4 class="text-white mb-3">
                Data Access
              </h4>

              <!-- Content -->
              <p class="text-white text-muted mb-0">
                We provide our partners with support accessing and transfering
                data to their own IT infrastrucure.
              </p>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- DATA
  ================================================== -->
    <section class="section bg-light">
      <!-- Line -->
      <div class="line line-top"></div>

      <!-- Triangles -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h2 class="text-center mb-4">
              Data
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5">
              We connect the data coming from multiple sources. The list is
              growing.
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-md-1" v-for="img in companiesImages" :key="img">
            <!-- Item -->
            <!-- <a class="card border-0 mb-3 mb-md-0"> -->
              <!-- Image -->
              <div class="card-img-applogo-top">
              <div class="card-img-applogo">
                <div class="card-img-applogo-innerbox">
                  <img
                    :src="img"
                    alt="App landing"
                    class="card-img-applogo-img"
                  />
                </div>
              </div></div>
            <!-- </a> -->
            <!-- / .card -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>
  </div>
</template>

<script>
export default {
  name: "APR",
  data() {
    return {
      companies: [
          "864950057.webp",
          "com.Electrolux.AEGRX9.webp",
          "com.Electrolux.PUREi9.webp",
          "com.adbglobal.bauknecht.webp",
          "com.adbglobal.whirlpool.webp",
          "com.aeg.airconditioner.webp",
          "com.aeg.myaeg.taste.webp",
          "com.aeg.myaeg.webp",
          "com.aeg.myaegapac.webp",
          "com.aeg.scale.webp",
          "com.aeg.wellbeing.webp",
          "com.aircare.electrolux.webp",
          "com.bshg.homeconnect.android.release.webp",
          "com.electrolux.airconditioner.webp",
          "com.electrolux.connectapp.arcon.webp",
          "com.electrolux.connectapp.lavaeseca.webp",
          "com.electrolux.connectapphub.android.webp",
          "com.electrolux.connectapphub.webp",
          "com.electrolux.ecp.coreapp.frigidaire.na.webp",
          "com.electrolux.egatetogo.webp",
          "com.electrolux.electroluxinstallerapp.webp",
          "com.electrolux.electroluxlife.webp",
          "com.electrolux.epr.Pricelist.webp",
          "com.electrolux.epr.ZanussiPricelist.webp",
          "com.electrolux.eprone3dr.webp",
          "com.electrolux.myelx.taste.webp",
          "com.electrolux.myelx.webp",
          "com.electrolux.oeno.webp",
          "com.electrolux.qesapp.webp",
          "com.electrolux.scale.webp",
          "com.electrolux.service.wifistrength.dev.uat.prod.webp",
          "com.electrolux.tracktrace.webp",
          "com.electrolux.visionmobile.webp",
          "com.electrolux.wellbeing.webp",
          "com.electrolux.wifibox.webp",
          "com.homewhiz.global.webp",
          "com.kelvinator.airconditioner.webp",
          "com.lgeha.nuts.webp",
          "com.liebherr.sdb.webp",
          "com.mize.electrolux.webp",
          "com.samsung.android.oneconnect.webp",
          "com.samsung.smarthome.webp",
          "com.smarthome.core.main.webp",
          "com.smeg.SmegConnect.webp",
          "de.miele.infocontrol.webp",
          "elux.egate.android.webp",
          "it.candy.simplyfi.webp",
          "it.hoover.wizard.webp",
          "nl.atag.connectlife.webp"
      ]
    };
  },
  computed: {
    companiesImages() {
      const imgs = [];
      for (let i = 0; i < this.companies.length; i += 1) {
        imgs.push(require("../assets/apps/" + this.companies[i]));
      }
      return imgs;
    }
  }
};
</script>

<style>
.img-small {
  width: 40%;
}

.card-img-applogo-top {
  margin: 0 0 48px 0;
}

.card-img-applogo-img {
  max-height: 48px;
  max-width: 48px;
}

.card-img-applogo-innerbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
